import React, {Fragment} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";
import {Translation} from "react-i18next";
import classes from './Rand.css';

function withHooks(WrappedComponent) {
    return function(props) {
        const nav = useNavigate();
        return <WrappedComponent nav={nav} {...props}/>
    };
}
class Rand extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'from': 0,
            'to': 1,
            'rand': 0
        };
    }

    handleFirstParam = (e) => {
        this.setState({from: e.target.value});
    };

    handleSecondParam = (e) => {
        this.setState({to: e.target.value});
    };

    randomNumberInRange = (min, max) => {
        const minCeiled = Math.ceil(min);
        const maxFloored = Math.floor(max);
        return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
        //return Math.floor(Math.random() * max);
    };

    getRand = () => {
        this.setState({rand: this.randomNumberInRange(this.state.from,this.state.to)});
    };

    render() {
        return (
            <header className="App-header d-grid gap-2">
            <Fragment>
                <input
                    id="rand_from"
                    type="text"
                    placeholder="from"
                    onInput={this.handleFirstParam}/>
                <input
                    id="rand_to"
                    type="text"
                    placeholder="to"
                    onInput={this.handleSecondParam}/>
                <Button className="weiter" variant="secondary" size="lg" onClick={this.getRand}>
                    <Translation>
                        {
                            t => <h1>{t('Get Rand')}</h1>
                        }
                    </Translation>
                </Button>
                <p className="rand_number">
                    {this.state.rand}
                </p>
            </Fragment>
            </header>
        );
    }
}

export default withHooks(Rand)