import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Container from 'react-bootstrap/Container';

import Rand from "./Rand";

function App() {
  return (
      <Container fluid="md" >
          <BrowserRouter>
            <div className="App">
                <Rand/>
            </div>
          </BrowserRouter>
      </Container>
  );
}

export default App;
